import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormProps,
  UploadProps,
  message,
  Upload,
  Spin,
  Input,
  Modal,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  InboxOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import { getHomeResultURL } from "routes/constants";
import { VOTRE_OPTIONS } from "constants/constant-jsx";

import { setProject } from "services/storages/projectStorage";

import Cover from "assets/images/cover.png";
import Cover2 from "assets/images/cover-2.jpg";
import Cover3 from "assets/images/cover-3.jpg";
import Cover4 from "assets/images/cover-4.jpg";
import Cover5 from "assets/images/cover-5.jpg";
import Cover6 from "assets/images/cover-6.jpg";
import Cover7 from "assets/images/cover-7.jpg";

import "styles/home.scss";

const coverBg = [Cover, Cover2, Cover3, Cover4, Cover5, Cover6, Cover7];

function Home() {
  const [formUpload] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [fileName, setFileName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [ademe, setAdeme] = useState("");
  const [bgCover, setBgCover] = useState("");

  const { data: dataBeforeUpload, isFetching: isFetchingDataUpload } = useQuery(
    {
      queryKey: [QUERY_KEYS.GET_DATA_UPLOAD, fileName],
      queryFn: () => API.mock.getDataUpload(fileName),
      enabled: !!fileName,
    }
  );

  const { mutateAsync: mutateUploadFile, isPending } = useMutation({
    mutationFn: ({ path, data }: { path: string; data: any }) =>
      API.mock.uploadFile(path, data),
  });

  const { mutateAsync: mutateResultat, isPending: isPendingResultat } =
    useMutation({
      mutationFn: (params: string) => API.mock.submitResultat(params),
    });

  useEffect(() => {
    const randomBg = _.sample(coverBg);
    setBgCover(randomBg);
  }, []);

  const handleUpload: FormProps["onFinish"] = async (values: any) => {
    // console.log("onFinish:", values.file[0]);
    try {
      const formData = new FormData();
      formData.append("key", dataBeforeUpload.fields.key);
      formData.append("AWSAccessKeyId", dataBeforeUpload.fields.AWSAccessKeyId);
      formData.append(
        "x-amz-security-token",
        dataBeforeUpload.fields["x-amz-security-token"]
      );
      formData.append("policy", dataBeforeUpload.fields.policy);
      formData.append("signature", dataBeforeUpload.fields.signature);
      formData.append("file", values.file[0].originFileObj);

      await mutateUploadFile({
        path: dataBeforeUpload.url,
        data: formData,
      });

      formUpload.resetFields();
      setFileName("");
      messageApi.open({
        type: "success",
        content: "Chargment OK",
      });
      navigate(getHomeResultURL(dataBeforeUpload?.metadata?.id));
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_MOCK_DATA] });
    } catch (e: any) {
      console.log(e.response);
      messageApi.open({
        type: "error",
        content: "Chargment Échec",
      });
    }
  };

  const handleSubmitResultat = async () => {
    const result = await mutateResultat(ademe);
    setProject(result.id);
    navigate(getHomeResultURL(result.id));
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const props: UploadProps = {
    accept: "*",
    name: "file",
    multiple: false,
    onChange({ file }) {
      setFileName(file.name);
    },
    beforeUpload: () => {
      return false;
    },
    showUploadList: true,
    maxCount: 1,
    disabled: isFetchingDataUpload,
  };

  return (
    <>
      <div
        className="energy-home__banner"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("${bgCover}")`,
        }}
      >
        <h1 className="energy-home__title">
          Exploitez votre DPE de façon
          <br /> fiable et actionable
        </h1>

        <Form
          form={formUpload}
          name="upload-form"
          autoComplete="off"
          layout="vertical"
          initialValues={{
            file: null,
            votre: "",
          }}
          onFinish={handleUpload}
        >
          <div
            className="flex items-start gap-8 inner-form"
            data-testid="upload-drag"
          >
            <Form.Item
              name="file"
              // label="Upload your DPE"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="w-full m-0"
            >
              <Upload.Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  {isFetchingDataUpload ? (
                    <Spin indicator={<LoadingOutlined />} size="large" />
                  ) : (
                    <InboxOutlined />
                  )}
                </p>
                <p className="ant-upload-text">
                  Déposez votre DPE (fichier pdf) ici pour l'uploader
                </p>
              </Upload.Dragger>
            </Form.Item>
          </div>
          {dataBeforeUpload && (
            <Button
              htmlType="submit"
              type="primary"
              loading={isPending}
              className="mt-4"
              size="large"
              disabled={!fileName || _.isEmpty(dataBeforeUpload)}
            >
              Upload
            </Button>
          )}
        </Form>

        <p className="jump-to-bottom" onClick={handleOpenModal}>
          ou saisissez votre réf. ADEME <RightOutlined />
        </p>
      </div>

      <div className="energy-home">
        {/* data-testid={`votre-${item.value}`} */}
        <div className="energy-home__votre">
          {VOTRE_OPTIONS.map((item, index) => (
            <Link
              to={item.url}
              key={index}
              className="energy-home__votre-item-link"
            >
              <div
                className="energy-home__votre-item"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("${item.thumbnail}")`,
                }}
              >
                <p className="energy-home__votre-item__title">{item.label}</p>
                <p className="energy-home__votre-item__desc">{item.desc}</p>
                {/* <img src={item.thumbnail} alt="votre-img"/> */}
              </div>
            </Link>
          ))}
        </div>

        {/* <div className="energy-home__section">
        <Row gutter={[0, 16]}>
          <Col span={24} md={12} className="energy-home__col">
            <div className="energy-home__box h-full">
              <Row gutter={[16, 16]}>
                <Col span={24} md={8}>
                  <div
                    className="project-item"
                    onClick={handleNavigateToListPage}
                  >
                    <img src={Sample1} alt="sample" />
                    <div className="project-item__info">
                      <p className="title mb-2">Rue destienn...</p>
                      <Badge
                        count="G"
                        color={MAP_COLOR_DPE["G"]}
                        style={{ minWidth: "40px" }}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24} md={8}>
                  <div
                    className="project-item"
                    onClick={handleNavigateToListPage}
                  >
                    <img src={Sample2} alt="sample" />
                    <div className="project-item__info">
                      <p className="title mb-2">RRue du Bac</p>
                      <Badge
                        count="B"
                        color={MAP_COLOR_DPE["B"]}
                        style={{ minWidth: "40px" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24} md={12} className="energy-home__col">
            <div
              className="energy-home__box h-full"
              style={{
                borderLeft: 0,
              }}
            >
              <div className="border border-black rounded-[10px] px-1 py-2">
                <ul className="list flex flex-col gap-3 list-disc pl-5">
                  <li className="green">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </li>
                  <li className="green">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </li>
                  <li className="yellow">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}

        <Modal
          centered
          open={openModal}
          onCancel={() => {
            setOpenModal(false);
            setAdeme("");
          }}
          footer={null}
        >
          <div className="energy-home__search mt-5">
            <label htmlFor="search-banner" className="w-full">
              votre numéro ADEME
              <Input
                id="search-banner"
                value={ademe}
                onChange={(e) => setAdeme(e.target.value)}
              />
            </label>
            <Button
              type="primary"
              loading={isPendingResultat}
              onClick={handleSubmitResultat}
            >
              résultats
            </Button>
          </div>
        </Modal>
        {contextHolder}
      </div>
    </>
  );
}

export default Home;
