import baseApi from "../api/base.api";
import createStorage from "./createStorage";

const instance = createStorage("energy");
const KEY_TOKEN = "token_api";

export const getValueUser = (key: string) => instance.getValue(key);

export const setToken = (data: string) => {
  instance.setValue(KEY_TOKEN, data);
  baseApi.setToken(btoa(data));
};

export const getToken = () => instance.getValue(KEY_TOKEN);

export const clearToken = () => {
  instance.deleteKey(KEY_TOKEN);
  baseApi.removeHeaderAttr("Authorization");
};
