import { useState } from "react";
import { Button, Card, Input, message } from "antd";

import { clearToken, getToken, setToken } from "services/storages/userStorage";

import "styles/settings.scss";

function SettingsPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const [key, setKey] = useState(getToken() || "");

  const handleSave = () => {
    if (!key.trim()) {
      clearToken();
      messageApi.open({
        type: "success",
        content: "Success",
      });
      return;
    }

    setToken(key.trim());
    messageApi.open({
      type: "success",
      content: "Success",
    });
  };

  return (
    <div className="energy-settings">
      <Card>
        <label htmlFor="key">
          <p
            className="mb-1"
            style={{
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            Key
          </p>
          <Input
            id="key"
            data-testid="keyToken"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </label>

        <Button type="primary" onClick={handleSave} className="mt-4">
          Save
        </Button>
      </Card>
      {contextHolder}
    </div>
  );
}

export default SettingsPage;
