import { Radio, Select } from "antd";
import { useMemo, useState } from "react";
import { MATERIAL_OPTIONS, MATERIAL_RADIO_OPTIONS } from "constants/form";

interface Props {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
}

function SelectMaterial({ id, value, onChange }: Props) {
  const [material, setMaterial] = useState(value);

  const triggerChange = (material: string) => {
    onChange?.(material);
  };

  const onMaterialChange = (value: any) => {
    const selectedMaterial = MATERIAL_RADIO_OPTIONS[value]
      ? MATERIAL_RADIO_OPTIONS[value][0].value
      : value;
    setMaterial(selectedMaterial);
    triggerChange(selectedMaterial);
  };

  const onRadioChange = (e: any) => {
    setMaterial(e.target.value);
    triggerChange(e.target.value);
  };

  const materialSelectValue = useMemo(() => {
    const materialSelect = Object.keys(MATERIAL_RADIO_OPTIONS).find((key) => {
      return MATERIAL_RADIO_OPTIONS[key].find((item: any) => {
        return item.value === material;
      });
    });
    return materialSelect || material;
  }, [material]);

  return (
    <div className="flex flex-col">
      <Select
        id={id}
        value={materialSelectValue}
        onChange={onMaterialChange}
        options={MATERIAL_OPTIONS}
      ></Select>

      {/* @ts-ignore */}
      {!!MATERIAL_RADIO_OPTIONS[materialSelectValue] && (
        <Radio.Group
          value={material}
          className="flex flex-col min-w-[280px] mt-2"
        >
          {/* @ts-ignore */}
          {MATERIAL_RADIO_OPTIONS[materialSelectValue].map(
            (item: any, index: number) => (
              <Radio value={item.value} key={index} onChange={onRadioChange}>
                {item.label}
              </Radio>
            )
          )}
        </Radio.Group>
      )}
    </div>
  );
}

export default SelectMaterial;
