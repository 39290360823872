import { Select } from "antd";
import { enveloppeOptions } from "constants/form";

function SelectEnveloppe({
  value,
  onChange,
}: {
  value: string | null;
  onChange?: (value: string) => void;
}) {
  //@ts-ignore
  return (
    <Select
      value={value}
      className="select-envelope"
      options={enveloppeOptions}
      onChange={onChange}
      placeholder="Select a enveloppe"
    ></Select>
  );
}

export default SelectEnveloppe;
