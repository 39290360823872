import { Select } from "antd";
import { useEffect, useState } from "react";
import { THICKNESS_OPTIONS } from "constants/form";

interface Props {
  id?: string;
  value?: string;
  material: string;
  onChange?: (value: string | undefined) => void;
}

function SelectThickness({ id, value, material, onChange }: Props) {
  const [thickness, setThickness] = useState(value);
  const [stateMaterial, setStateMaterial] = useState(material);

  useEffect(() => {
    // if (THICKNESS_OPTIONS[material]) {
    //   setThickness(THICKNESS_OPTIONS[material][0].value);
    // }
    if (stateMaterial !== material) {
      setThickness(undefined);
      triggerChange(undefined);
      setStateMaterial(material);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [material]);

  const triggerChange = (thickness: string | undefined) => {
    onChange?.(thickness);
  };

  const onThicknessChange = (value: any) => {
    setThickness(value);
    triggerChange(value);
  };

  return (
    <Select
      id={id}
      value={thickness}
      onChange={onThicknessChange}
      options={THICKNESS_OPTIONS[material]}
      style={{ width: "80px" }}
    ></Select>
  );
}

export default SelectThickness;
