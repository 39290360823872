import {
  AppstoreOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";
import PAGES, { getHomeResultURL } from "./constants";
import { getProject } from "services/storages/projectStorage";

export enum MENU_NAVIGATION_VALUE {
  MAIN = "main",
  LIST = "list",
}

export const getMenuNavigation = () => {
  const isLastProject = getProject();
  const MENU_NAVIGATION = [
    {
      key: PAGES.home,
      icon: <AppstoreOutlined />,
      label: "Main",
    },
    {
      key: PAGES.list,
      icon: <UnorderedListOutlined />,
      label: "Projets",
    },
    {
      key: PAGES.settings,
      icon: <SettingOutlined />,
      label: "Settings",
    },
  ];

  if (isLastProject) {
    MENU_NAVIGATION.splice(1, 0, {
      key: getHomeResultURL(isLastProject),
      icon: <FundProjectionScreenOutlined />,
      label: "Projet en cours",
    });
  }

  return MENU_NAVIGATION;
};

export default PAGES;
