import { Form, Select } from "antd";
import { expositionOptions } from "constants/form";

function SelectExposition({
  name,
  label,
  onChange,
}: {
  name: string;
  label: string;
  onChange?: (value: string) => void;
}) {
  //@ts-ignore
  return (
    <Form.Item label={label} name={name}>
      <Select options={expositionOptions} onChange={onChange}></Select>
    </Form.Item>
  );
}

export default SelectExposition;
