import React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  Link,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Layout, Menu, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useScroll, motion, useTransform } from "framer-motion";
import classNames from "classnames";

import InsightPage from "./pages/insight";
import useCommonStore from "./stores/useCommon";

import IconInsight from "assets/images/insights.png";
// import LogoMain from "assets/images/logo-main.png";
// import LogoMobile from "assets/images/logo-mobile.png";

import PAGES, { getMenuNavigation } from "./routes/menu";
import { getInsightURL } from "./routes/constants";

const { Content } = Layout;

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });

  // const containerRef = useRef<any>();
  const { scrollYProgress } = useScroll({});
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { isShowInsight, setIsShowInsight } = useCommonStore();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(location.pathname);
  const headerBg = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 0.7)"]
  );
  const headerFilter = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["blur(0)", "blur(15px)"]
  );
  const menuColor = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["rgba(255, 255, 255, 1)", "rgba(0, 0, 0, 1)"]
  );

  useEffect(() => {
    setIsShowInsight(false);

    const navigation = getMenuNavigation();
    for (let item of navigation) {
      if (matchPath(item.key, location.pathname)) {
        setSelectedKeys(item.key);
        return;
      }
    }

    if (
      [PAGES.homeResult, PAGES.acheter, PAGES.vendre, PAGES.renover].some(
        (path) => matchPath(path, location.pathname)
      )
    ) {
      setSelectedKeys(PAGES.home);
    } else {
      setSelectedKeys(location.pathname);
    }

    window.scrollTo({ top: 0 });
  }, [location.pathname, setIsShowInsight]);

  useEffect(() => {
    if (isTabletOrMobile) {
      setIsOpenMenu(false);
    }
  }, [isTabletOrMobile]);

  const handleClickMenu = ({ key }: any) => {
    setIsOpenMenu(false);
    navigate(key);
  };

  const handleToInsight = () => {
    if (isDesktop) {
      setIsShowInsight(!isShowInsight);
    } else {
      navigate(getInsightURL(String(id)));
    }
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const isAllowedShowInsight = useMemo(() => {
    if (matchPath(PAGES.homeResult, location.pathname)) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const isHomepage = useMemo(() => {
    if (
      [PAGES.home, PAGES.acheter, PAGES.vendre, PAGES.renover].some((path) =>
        matchPath(path, location.pathname)
      )
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  return (
    <div className="energy-app">
      <Layout
        className={classNames({
          "energy-layout--with-insight": isShowInsight,
        })}
      >
        <Layout>
          <motion.div
            initial={{ backgroundColor: `rgba(255, 255, 255, 0)` }}
            style={{
              backgroundColor: headerBg,
              backdropFilter: headerFilter,
            }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className={classNames("ant-layout-header", {
              "header--fixed": isHomepage,
              "header--white": !isHomepage,
            })}
          >
            <div className="flex items-center gap-x-3 sm:gap-x-2">
              <motion.button
                onClick={() => setIsOpenMenu(!isOpenMenu)}
                className="ant-btn ant-btn-text ant-btn-icon-only btn-burger"
                style={{
                  fontSize: "16px",
                  width: isTabletOrMobile ? 32 : 64,
                  height: 64,
                  color: isHomepage ? menuColor : "#000",
                }}
              >
                <span className="ant-btn-icon">
                  <span className="anticon anticon-menu">
                    <MenuOutlined />
                  </span>
                </span>
              </motion.button>

              {/* {isTabletOrMobile ? (
                <Link to={PAGES.home}>
                  <img src={LogoMobile} alt="logo" className="w-5" />
                </Link>
              ) : (
                <Link to={PAGES.home}>
                  <img src={LogoMain} alt="logo" className="w-24" />
                </Link>
              )} */}

              <Link to={PAGES.home}>
                <motion.h1
                  className="logo"
                  style={{
                    color: isHomepage ? menuColor : "#000",
                  }}
                >
                  LeDPE
                </motion.h1>
              </Link>
            </div>

            {isAllowedShowInsight && (
              <img
                src={IconInsight}
                alt="insight"
                width={24}
                onClick={handleToInsight}
                className="cursor-pointer"
              />
            )}
          </motion.div>
          <Content
            className={classNames({
              "content--homepage": isHomepage,
            })}
            style={{
              margin: !isHomepage ? "64px 16px 24px" : 0,
              padding: !isHomepage ? 24 : 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>

        {isShowInsight && (
          <div className="insight-side">
            <InsightPage />
          </div>
        )}
      </Layout>

      <Drawer
        title="LeDPE"
        placement="left"
        closable={false}
        onClose={handleCloseMenu}
        open={isOpenMenu}
        className="drawer-menu"
        width={250}
      >
        <Menu
          mode="inline"
          onClick={handleClickMenu}
          selectedKeys={[selectedKeys]}
          items={getMenuNavigation()}
        />
      </Drawer>
    </div>
  );
}

export default App;
