import { create } from "zustand";

export interface CommonType {
  isShowInsight: boolean;
  setIsShowInsight: (isShowInsight: boolean) => void;
}

const useCommonStore = create<CommonType>((set) => ({
  isShowInsight: false,
  setIsShowInsight: (isShowInsight: boolean) => set(() => ({ isShowInsight })),
}));

export default useCommonStore;
