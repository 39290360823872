//@ts-nocheck
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import classNames from "classnames";
import * as d3 from "d3";
import {
  Button,
  Spin,
  Row,
  Col,
  Card,
  Select,
  Skeleton,
  message,
  Empty,
  Flex,
  Tabs,
  Progress,
  Modal,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { InfoCircleOutlined } from "@ant-design/icons";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import PAGES from "routes/constants";
import { MAP_COLOR_DPE } from "constants/index";
import { ReactComponent as IconPen } from "assets/images/pen.svg";
import { ReactComponent as IconSetting } from "assets/images/setting.svg";
import { ReactComponent as IconLocation } from "assets/images/location.svg";
import { ReactComponent as IconHouse } from "assets/images/house.svg";
import { ReactComponent as IconRuler } from "assets/images/ruler.svg";
import { ReactComponent as IconDoor } from "assets/images/door.svg";
import { ReactComponent as IconCalendar } from "assets/images/calendar-2.svg";
import { ReactComponent as IconStair } from "assets/images/stair.svg";
import { ReactComponent as IconEnergy } from "assets/images/energy.svg";
import { ReactComponent as IconTemperature } from "assets/images/temperature.svg";
import { ReactComponent as IconWater } from "assets/images/water.svg";
import { ReactComponent as IconWall } from "assets/images/wall.svg";
import { ReactComponent as IconWind } from "assets/images/wind.svg";
import { ReactComponent as IconIce } from "assets/images/ice.svg";
import "styles/home.scss";

const PADDING_LABEL = 10;

function HomeResult() {
  const [messageApi, contextHolder] = message.useMessage();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const barChartRef = useRef<any>();
  const navigate = useNavigate();
  const { id } = useParams();

  const [dataChart, setDataChart] = useState<any[]>([]);
  // const [, setMap] = useState(null);
  const [dataHomeUploaded, setDataHomeUploaded] = useState(null);
  const [Resultat, setResultat] = useState(null);
  const [tab, setTab] = useState("1");
  const [tabMap, setTabMap] = useState("2");
  const [isShowModalMapInfo, setIsShowModalMapInfo] = useState(false);

  // const { data: dataHomeUploaded, isFetching: isFetchingHomeUploaded } =
  //   useQuery({
  //     queryKey: [QUERY_KEYS.GET_HOME_DATA_UPLOADED],
  //     queryFn: API.mock.getHomeUploadData,
  //   });

  const {
    mutateAsync: mutateUploaded,
    isPending: isPendingUploaded,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: (params: any) => API.mock.getHomeUploadData(params),
  });

  const { mutateAsync: mutateSVG, isPending: isPendingSvg } = useMutation({
    mutationFn: (params: any) => API.mock.getResultat(params),
  });

  // const { data: Resultat } = useQuery({
  //   queryKey: [QUERY_KEYS.GET_HOME_DATA_RESULTAT],
  //   queryFn: API.mock.getResultat,
  // });

  const { data: geoMap } = useQuery({
    queryKey: [QUERY_KEYS.GET_GEO_MAP],
    queryFn: API.mock.getGEOMap,
  });

  console.log(geoMap);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const promises = [
        mutateUploaded({
          id,
        }),
        mutateSVG({ id }),
      ];

      const [result, resultSVG] = await Promise.all(promises);

      setDataHomeUploaded(result);
      setResultat(resultSVG);
    } catch (e: any) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    }
  };

  useEffect(() => {
    if (dataHomeUploaded) {
      const cloneData = _.cloneDeep(
        _.get(dataHomeUploaded, "distribution.values", {})
      ) as any;

      const convertedData = Object.keys(cloneData).map((key) => {
        return {
          label: key,
          value: cloneData[key] * 100,
          color: MAP_COLOR_DPE[key],
        };
      });
      setDataChart(convertedData);
    }
  }, [dataHomeUploaded]);

  useEffect(() => {
    if (!_.isEmpty(dataChart) && !isPendingUploaded) {
      drawChart();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChart, isPendingUploaded, isTabletOrMobile]);

  const handleToEditPage = () => {
    if (dataHomeUploaded) {
      navigate({
        pathname: PAGES.edit.replace(":id", (dataHomeUploaded as any).id),
        // search: createSearchParams({
        //   mode: "edit",
        // }).toString(),
      });
    }
  };

  const drawChart = () => {
    const width = isTabletOrMobile ? 332 : 600;
    const height = 350;
    const heightLabel = 40;
    const margin = {
      top: 20,
      right: isTabletOrMobile ? 0 : 10,
      bottom: 60,
      left: isTabletOrMobile ? 0 : 10,
    };

    const isExist = d3.select(".bar-svg");
    if (isExist) {
      d3.select(".bar-svg").remove();
    }

    const svg = d3
      .select(barChartRef.current)
      .append("svg")
      .attr("class", "bar-svg")
      .attr("width", width)
      .attr("height", height);

    const xScale = d3
      .scaleBand()
      .domain(dataChart.map((d) => d.label))
      .range([margin.left, width - margin.right]);
    // .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, 100])
      .nice()
      .range([height - margin.bottom, margin.top]);

    svg
      .selectAll(".bar")
      .data(dataChart)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => (xScale(d.label) || 0) + 12.5)
      .attr("y", (d) => yScale(d.value))
      .attr("width", xScale.bandwidth() - 25)
      .attr("height", (d) => yScale(0) - yScale(d.value));

    svg
      .selectAll(".percentage")
      .data(dataChart)
      .enter()
      .append("text")
      .attr("class", "percentage")
      .attr("x", (d) => (xScale(d.label) || 0) + xScale.bandwidth() / 2)
      .attr("y", (d) => {
        if (d.value === 0) {
          return yScale(d.value) - 5;
        }
        return yScale(d.value) + 15;
      })
      .text((d) => d.value + "%");

    svg
      .selectAll(".box-wrapper")
      .data(dataChart)
      .enter()
      .append("rect")
      .attr("class", "box-wrapper")
      .attr("x", (d) => xScale(d.label) || 0)
      .attr("y", height - margin.bottom)
      .attr("width", xScale.bandwidth())
      .attr("height", heightLabel)
      .attr("fill", (d) => "#c4c4bc");

    svg
      .selectAll(".box")
      .data(dataChart)
      .enter()
      .append("rect")
      .attr("class", "box")
      .attr("x", (d) => (xScale(d.label) || 0) + PADDING_LABEL / 2)
      .attr("y", height - margin.bottom + PADDING_LABEL / 2)
      .attr("width", xScale.bandwidth() - PADDING_LABEL)
      .attr("height", heightLabel - PADDING_LABEL)
      .attr("fill", (d) => d.color);

    svg
      .selectAll(".label")
      .data(dataChart)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => (xScale(d.label) || 0) + xScale.bandwidth() / 2)
      .attr("y", height - margin.bottom + 20 + PADDING_LABEL / 2)
      .text((d) => d.label);

    // Arrow
    const arrowSize = 10;
    const arrowPosition =
      (xScale((dataHomeUploaded as any)?.results?.dpe_letter) || 0) +
      xScale.bandwidth() / 2;

    svg
      .append("polygon")
      .attr("class", "arrow")
      .attr(
        "points",
        `${arrowPosition},${height - margin.bottom + 42} 
                         ${arrowPosition - arrowSize},${
          height - margin.bottom + 57
        } 
                         ${arrowPosition + arrowSize},${
          height - margin.bottom + 57
        }`
      );
  };

  // const pdfFileName = useMemo(() => {
  //   if (dataHomeUploaded) {
  //     const arrString = (dataHomeUploaded as any).pdf?.split("/");
  //     return arrString[arrString.length - 1];
  //   }
  //   return "";
  // }, [dataHomeUploaded]);

  const tabItems: TabsProps["items"] = useMemo(() => {
    const renderTabDuBien = () => {
      const TAB_DU_BIEN1 = [
        {
          icon: IconRuler,
          label: "",
          value: _.get(dataHomeUploaded, "project_details.surface.1", ""),
        },
        {
          icon: IconDoor,
          label: "",
          value: _.get(dataHomeUploaded, "project_details.housing_setup.1", ""),
        },
        {
          icon: IconCalendar,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.construction_year.0",
            ""
          )} `,
          value: _.get(
            dataHomeUploaded,
            "project_details.construction_year.1",
            ""
          ),
        },
        {
          icon: IconStair,
          label: `${_.get(dataHomeUploaded, "project_details.floor.0", "")} : `,
          value: _.get(dataHomeUploaded, "project_details.floor.1", ""),
        },
        {
          icon: IconEnergy,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.energy.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.energy.1", ""),
        },
      ];

      const TAB_DU_BIEN2 = [
        {
          icon: IconTemperature,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.heating_system.0",
            ""
          )} : `,
          value: _.get(
            dataHomeUploaded,
            "project_details.heating_system.1",
            ""
          ),
        },
        {
          icon: IconWater,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.hot_water.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.hot_water.1", ""),
        },
        {
          icon: IconWall,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.materials.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.materials.1", ""),
        },
        {
          icon: IconWind,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.airflow.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.airflow.1", ""),
        },
        {
          icon: IconIce,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.aircon.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.aircon.1", ""),
        },
      ];

      return (
        <div className="info-du-bien">
          <ul>
            {TAB_DU_BIEN1.map((item, index) => {
              const Icon = item.icon;
              return (
                <li key={index}>
                  <Flex align="center">
                    <Icon className="mr-1" />
                    {item.label && (
                      <span className="text-medium mr-1">{item.label}</span>
                    )}
                    {item.value && (
                      <span className="text-medium-bold capitalize">
                        {item.value}
                      </span>
                    )}
                  </Flex>
                </li>
              );
            })}
          </ul>

          <ul>
            {TAB_DU_BIEN2.map((item, index) => {
              const Icon = item.icon;
              return (
                <li key={index}>
                  <Flex align="center">
                    <Icon className="mr-1 flex-shrink-0" />
                    {item.label && (
                      <p className="text-medium mr-1">{item.label} </p>
                    )}
                    {item.value && (
                      <p className="text-medium-bold capitalize truncate max-w-[15%] sm:max-w-[40%]">
                        {item.value}
                      </p>
                    )}
                  </Flex>
                </li>
              );
            })}
          </ul>
        </div>
      );
    };

    return [
      { key: "1", label: "Infos du bien", children: renderTabDuBien() },
      { key: "2", label: "Infos du DPE", children: "Content of Tab Pane 2" },
    ];
  }, [dataHomeUploaded]);

  const renderMap = () => {
    return (
      <div className="map-wrapper">
        <MapContainer
          center={[
            _.get(dataHomeUploaded, "geo_data.lat"),
            _.get(dataHomeUploaded, "geo_data.lng"),
          ]}
          zoom={15}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[
              _.get(dataHomeUploaded, "geo_data.lat"),
              _.get(dataHomeUploaded, "geo_data.lng"),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          {/* <Circle
        center={[10.7352, 106.6299]}
        color="red"
        radius={500}
        fillColor="#f03"
        fillOpacity={0.5}
      />
      <Polygon
        positions={[
          [10.73617, 106.64103],
          [10.73447, 106.64395],
          [10.73647, 106.64495],
        ]}
      /> */}
        </MapContainer>
      </div>
    );
  };

  const tabMapItems: TabsProps["items"] = [
    { key: "1", label: "Plan", children: renderMap() },
    { key: "2", label: "Satellite", children: renderMap() },
    { key: "3", label: "Cadastre", children: renderMap() },
  ];

  const renderResultUploaded = () => {
    if (isPendingUploaded) {
      return (
        <div className="loading-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      );
    }

    if (isError && _.isEmpty(dataHomeUploaded)) {
      return (
        <Card className="mb-3 min-h-[400px] flex items-center justify-center">
          <Empty />
        </Card>
      );
    }

    if (isSuccess && !_.isEmpty(dataHomeUploaded)) {
      return (
        <div className="energy-home__result">
          <Card
            title={<h4>{_.get(dataHomeUploaded, "project_name")}</h4>}
            className="energy-home__project-card mb-3"
          >
            <div className="energy-home__project">
              <Flex align="center" className="mb-2">
                <IconLocation />
                <span className="text-medium-bold ml-1">
                  {_.get(dataHomeUploaded, "project_details.address.1", "")}
                </span>
              </Flex>
              <Flex align="center">
                <IconHouse />
                <p className="text-medium-medium ml-1">
                  {_.get(
                    dataHomeUploaded,
                    "project_details.housing_type.0",
                    ""
                  )}
                  <span className="text-medium-bold">
                    {" "}
                    :{" "}
                    {_.get(
                      dataHomeUploaded,
                      "project_details.housing_type.1",
                      ""
                    )}
                  </span>
                </p>
              </Flex>

              <div className="energy-home__project-info">
                <Tabs
                  centered
                  activeKey={tab}
                  items={tabItems}
                  onChange={setTab}
                />

                <div className="energy-home__project-info__indice">
                  <p className="text-large-bold mb-1">Indice de fiabilité</p>
                  <Flex className="mb-4" align="baseline">
                    <h5>8,2</h5>
                    <h6>/10</h6>
                  </Flex>
                  <Progress
                    showInfo={false}
                    percent={82}
                    trailColor="#CCCCCC"
                    strokeColor="#000000"
                  />
                </div>
              </div>
            </div>
          </Card>

          <Row
            gutter={[16, 16]}
            className="mb-3"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col span={24} lg={12} style={{ paddingLeft: 0 }}>
              <Card>
                <div className="energy-home__resultats">
                  <h3 className="text-xl mb-3">
                    <b>Résultats</b>
                  </h3>
                  <div className="text-center">
                    {isPendingSvg ? (
                      <div className="svg-image">
                        <Skeleton.Button active />
                      </div>
                    ) : (
                      <img
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          Resultat || ""
                        )}`}
                        alt="result"
                      />
                    )}

                    <Button
                      type="primary"
                      onClick={handleToEditPage}
                      className="mt-7"
                    >
                      ouvrir DPE
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={24} lg={12} style={{ paddingRight: 0 }}>
              <Card className="h-full">
                <div className="energy-home__corrections">
                  <h3 className="text-xl mb-4">
                    <b>Corrections suggérées</b>
                  </h3>
                  <div className="flex flex-col gap-3">
                    {!!_.get(dataHomeUploaded, "suggested", []).length &&
                      _.get(dataHomeUploaded, "suggested", []).map(
                        (item: any, index: number) => {
                          const Icon = MAP_CORRECTION_ICON[item.type];
                          return (
                            <div className="corrections-item" key={index}>
                              <Row gutter={[4, 4]}>
                                <Col
                                  span={3}
                                  lg={1}
                                  className="flex items-center justify-center"
                                >
                                  <Icon />
                                </Col>
                                <Col
                                  span={21}
                                  lg={23}
                                  className="flex items-center"
                                >
                                  <p>{item.message}</p>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      )}
                  </div>

                  <div className="text-center mt-4">
                    <Button disabled className="min-w-[108px]">
                      Plus
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <Card className="mb-3">
            <div className="energy-home__notifications">
              <h3 className="text-xl mb-4">
                <b>Notifications</b>
              </h3>
              <ul className="notification flex flex-col items-center gap-3 list-disc pl-5">
                {!!_.get(dataHomeUploaded, "notifications", []).length &&
                  _.get(dataHomeUploaded, "notifications", []).map(
                    (item: any, index: number) => {
                      return (
                        <li
                          className={classNames([
                            "notification-item",
                            item.type,
                          ])}
                          key={index}
                        >
                          <span>{item.message}</span>
                        </li>
                      );
                    }
                  )}
              </ul>

              <div className="text-center mt-6">
                <Button disabled className="min-w-[108px]">
                  Plus
                </Button>
              </div>
            </div>
          </Card>

          <Card className="mb-3">
            <div className="energy-home__distribution">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-xl">
                    <b>{_.get(dataHomeUploaded, "distribution.title", "")}</b>
                  </h3>
                  <p>{_.get(dataHomeUploaded, "distribution.subtitle", "")}</p>
                </div>

                <div>
                  <p className="mb-0.5">Scope</p>
                  <Select
                    className="w-[120px]"
                    defaultValue={_.get(dataHomeUploaded, "distribution.scope")}
                    options={[
                      {
                        label: _.get(dataHomeUploaded, "distribution.scope"),
                        value: _.get(dataHomeUploaded, "distribution.scope"),
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="bar-chart" ref={barChartRef}></div>
            </div>
          </Card>

          <Card>
            <div className="energy-home__maps">
              <div className="flex justify-between">
                <h3 className="text-xl">
                  <b>Maps</b>
                </h3>
                <Button
                  type="link"
                  className="flex items-center justify-center p-0 w-9 h-9"
                  onClick={() => setIsShowModalMapInfo(true)}
                >
                  <InfoCircleOutlined style={{ color: "#000", fontSize: 22 }} />
                </Button>
              </div>

              <Flex align="center" className="mt-2 mb-4">
                <IconLocation />
                <span className="text-regular-bold sm:text-medium-bold ml-1">
                  {_.get(dataHomeUploaded, "project_details.address.1", "")}
                </span>
              </Flex>

              <div className="energy-home__map-info">
                <Tabs
                  centered
                  activeKey={tabMap}
                  items={tabMapItems}
                  onChange={setTabMap}
                />
              </div>
            </div>
          </Card>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="energy-home energy-home--result">
      {renderResultUploaded()}
      {contextHolder}
      <Modal
        open={isShowModalMapInfo}
        centered
        onCancel={() => setIsShowModalMapInfo(false)}
        onOk={() => setIsShowModalMapInfo(false)}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div className="p-4">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
            unde vitae sit placeat mollitia molestias animi quasi amet eaque
            quo, culpa delectus quae quisquam iusto obcaecati dicta voluptate.
            Deleniti, aspernatur?
          </p>
        </div>
      </Modal>
    </div>
  );
}

const MAP_CORRECTION_ICON: any = {
  completion: IconPen,
  construction: IconSetting,
};

export default HomeResult;
