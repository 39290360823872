import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Typography,
  Spin,
  Form,
  Button,
  AutoComplete,
  Modal,
  Upload,
  UploadProps,
  FormProps,
  message,
} from "antd";
import {
  LoadingOutlined,
  ArrowLeftOutlined,
  EditFilled,
  CheckOutlined,
  FileDoneOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import classNames from "classnames";

import RowData from "components/home/row-data";
import SelectEnveloppe from "components/form/enveloppe/select-enveloppe";
import FormElementGeneralites from "components/form/generalites/form-element";

import API from "services/api";
import QUERY_KEYS from "services/api/queryKeys";
// import { materialSelectValeWithRadios } from "utils";
import type { FeatureType } from "services/api/type/mock.type";
import "styles/edit.scss";

let hash = {} as any;

function EditPage() {
  const [query] = useSearchParams();
  const [form] = Form.useForm();
  const [formUpload] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  // const fileForm = Form.useWatch("file", formUpload);

  const navigate = useNavigate();
  const dataForm = Form.useWatch("enveloppe", form);
  const queryClient = useQueryClient();

  const [newEnveloppe, setNewEnveloppe] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [searchAPI, setSearchAPI] = useState<string>("");
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [convertData, setConvertData] = useState([]);
  const [fileName, setFileName] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_MOCK_DATA],
    queryFn: API.mock.getMockData,
  });

  const { data: modalData, isLoading: isLoadingModalData } = useQuery({
    queryKey: [QUERY_KEYS.GET_MODAL_DATA],
    queryFn: API.mock.getModalData,
  });

  const { data: dataAutoComplete } = useQuery({
    queryKey: [QUERY_KEYS.AUTOCOMPLETE_SEARCH],
    queryFn: () => API.mock.autoCompleteSearch(searchAPI),
    enabled: !!searchAPI,
  });

  const { data: dataBeforeUpload, isFetching: isFetchingDataUpload } = useQuery(
    {
      queryKey: [QUERY_KEYS.GET_DATA_UPLOAD, fileName],
      queryFn: () => API.mock.getDataUpload(fileName),
      enabled: !!fileName,
    }
  );

  const { mutateAsync: mutateUploadFile, isPending } = useMutation({
    mutationFn: ({ path, data }: { path: string; data: any }) =>
      API.mock.uploadFile(path, data),
  });

  useEffect(() => {
    if (query.get("mode") === "edit") {
      setIsEditable(true);
    }
  }, [query]);

  useEffect(() => {
    form.resetFields();
    if (!_.isEmpty(data)) {
      setSearch(_.get(data, "address.data.properties.label", ""));
    }

    hash = {};
    const enveloppe = _.get(data, "enveloppe", []);
    const result = enveloppe.map((item: any) => {
      const type_enveloppe = _.get(item, "type_enveloppe");
      if (hash[type_enveloppe]) {
        hash[type_enveloppe] = hash[type_enveloppe] + 1;
      } else {
        hash[type_enveloppe] = 1;
      }

      const result = {
        ...item,
        appearance: hash[type_enveloppe],
      };
      return result;
    });

    form.setFieldValue("enveloppe", result);
    setConvertData(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.AUTOCOMPLETE_SEARCH],
    });
  }, [queryClient, searchAPI]);

  useEffect(() => {
    const features: FeatureType[] = _.get(dataAutoComplete, "features", []);
    const newOptions = features.map((item) => ({
      value: _.get(item, "properties.label", ""),
    }));
    setOptions(newOptions);
  }, [dataAutoComplete]);

  const handleChangeEnveloppe = (value: string) => {
    setNewEnveloppe(value);
  };

  const handleAddEnveloppe = (add: Function) => {
    if (newEnveloppe) {
      if (hash[newEnveloppe]) {
        hash[newEnveloppe] = hash[newEnveloppe] + 1;
      } else {
        hash[newEnveloppe] = 1;
      }
      add({
        type_enveloppe: newEnveloppe,
        isAddNew: true,
        appearance: hash[newEnveloppe],
      });
      setNewEnveloppe(null);
    }
  };

  const handleDeleteRow = (field: any, remove: Function) => {
    const enveloppeItem = form.getFieldValue(["enveloppe", field.name]);
    if (hash[enveloppeItem.type_enveloppe]) {
      hash[enveloppeItem.type_enveloppe] -= 1;
    }

    remove(field.name);

    hash = {};
    const enveloppe = form.getFieldValue("enveloppe");
    const result = enveloppe.map((item: any) => {
      const type_enveloppe = _.get(item, "type_enveloppe");
      if (hash[type_enveloppe]) {
        hash[type_enveloppe] = hash[type_enveloppe] + 1;
      } else {
        hash[type_enveloppe] = 1;
      }

      const result = {
        ...item,
        appearance: hash[type_enveloppe],
      };
      return result;
    });

    form.setFieldValue("enveloppe", result);
  };

  const renderDataEnveloppe = () => {
    return (
      <>
        <Form.List name="enveloppe">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field) => {
                return (
                  <RowData
                    field={field}
                    isQuickView={!isEditable}
                    data={_.get(dataForm, field.name, {})}
                    key={field.key}
                    appearance={_.get(dataForm, `${field.name}.appearance`, 0)}
                    onDelete={() => handleDeleteRow(field, remove)}
                  />
                );
              })}
              {isEditable && (
                <div className="row-data">
                  <div className="col-title"></div>
                  <div className="col-info">
                    <SelectEnveloppe
                      value={newEnveloppe}
                      onChange={handleChangeEnveloppe}
                    />

                    <Button
                      disabled={!newEnveloppe}
                      type="default"
                      size="large"
                      onClick={() => {
                        handleAddEnveloppe(add);
                      }}
                      block
                    >
                      Add New
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Form.List>
      </>
    );
  };

  const renderDataGeneralites = () => {
    const listKey = Object.keys(data?.generalites || {});
    return (
      <>
        {(listKey || []).map((key, index) => {
          return (
            <FormElementGeneralites
              isQuickView={!isEditable}
              field={key}
              value={_.get(data, `generalites.${key}`, null)}
              key={index}
            />
          );
        })}
      </>
    );
  };

  const handleSubmit = (dataSubmit: any) => {
    console.log("submit ------------", dataSubmit);

    const generalites = _.cloneDeep(data?.generalites);
    Object.keys(generalites).forEach((key) => {
      generalites[key] = _.merge(
        generalites[key],
        _.get(dataSubmit, `generalites.${key}`)
      );
    });

    console.log("generalites ------------", generalites);
  };

  const onSelect = (data: string) => {
    console.log("onSelect", data);
    setSearch(data);
  };

  const handleSearch = (text: string) => {
    setSearch(text);
    handleThrottleSearch(text);
  };

  const handleSearchAPI = (text: string) => {
    setSearchAPI(text);
  };

  const handleBackToPrevious = () => {
    navigate(-1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleThrottleSearch = useCallback(
    _.throttle(handleSearchAPI, 1000),
    []
  );

  const handleUpload: FormProps["onFinish"] = async (values: any) => {
    console.log("onFinish:", values.file[0]);
    try {
      const formData = new FormData();
      formData.append("key", dataBeforeUpload.fields.key);
      formData.append("AWSAccessKeyId", dataBeforeUpload.fields.AWSAccessKeyId);
      formData.append(
        "x-amz-security-token",
        dataBeforeUpload.fields["x-amz-security-token"]
      );
      formData.append("policy", dataBeforeUpload.fields.policy);
      formData.append("signature", dataBeforeUpload.fields.signature);
      formData.append("file", values.file[0].originFileObj);

      await mutateUploadFile({
        path: dataBeforeUpload.url,
        data: formData,
      });

      formUpload.resetFields();
      setFileName("");
      messageApi.open({
        type: "success",
        content: "Chargment OK",
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_MOCK_DATA] });
    } catch (e: any) {
      console.log(e.response);
      messageApi.open({
        type: "error",
        content: "Chargment Échec",
      });
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const props: UploadProps = {
    accept: "*",
    name: "file",
    multiple: false,
    onChange({ file }) {
      setFileName(file.name);
    },
    beforeUpload: () => {
      return false;
    },
    showUploadList: true,
    maxCount: 1,
  };

  // console.log("Form data", dataForm);
  if (isLoading) {
    return (
      <div className="loading-fullscreen">
        <Spin size="large" indicator={<LoadingOutlined />} fullscreen />
      </div>
    );
  }

  return (
    <main className="homepage">
      <div className="top-bar">
        {!isEditable ? (
          <>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={handleBackToPrevious}
            />
            <Button
              type="text"
              icon={<EditFilled style={{ color: "#2a4dbe" }} />}
              onClick={() => {
                setIsEditable(true);
              }}
            />
            <Button
              type="text"
              disabled={isLoadingModalData}
              icon={<FileDoneOutlined style={{ color: "#2a4dbe" }} />}
              onClick={() => setIsShowModal(true)}
            />
          </>
        ) : (
          <>
            <Button
              type="text"
              icon={<CheckOutlined style={{ color: "#2a4dbe" }} />}
              onClick={() => {
                setNewEnveloppe(null);
                setIsEditable(false);
              }}
            />
            <span className="ml-2">Editer</span>
          </>
        )}
      </div>

      <div className="content">
        {isEditable && (
          <Form
            form={formUpload}
            name="upload-form"
            autoComplete="off"
            initialValues={{
              file: null,
            }}
            colon={isEditable}
            onFinish={handleUpload}
          >
            <div className="flex items-start gap-8">
              <Form.Item
                name="file"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...props}>
                  <Button
                    icon={<UploadOutlined />}
                    loading={isFetchingDataUpload}
                  >
                    Choose file
                  </Button>
                </Upload>
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                loading={isPending}
                disabled={!fileName || _.isEmpty(dataBeforeUpload)}
              >
                Upload
              </Button>
            </div>
          </Form>
        )}

        <div className="data-table data-table__generalites mb-6">
          <Typography.Title level={2}>ADRESSE</Typography.Title>
          <div className="data-table__body data-table__body--padding-small">
            {!isEditable && (
              <p className="font-bold ant-value">{search ? search : "N/A"}</p>
            )}
            <AutoComplete
              allowClear
              options={options}
              value={search}
              className={classNames("w-full mb-5 mr-4", {
                "has-value": search,
                hidden: !isEditable,
              })}
              onSelect={onSelect}
              onSearch={handleSearch}
              placeholder="Search"
            />
          </div>
        </div>

        <Form
          disabled={!isEditable}
          labelCol={{ md: 12, span: 12 }}
          wrapperCol={{ md: 12, span: 12 }}
          form={form}
          name="mock_data"
          autoComplete="off"
          initialValues={{
            enveloppe: convertData,
            generalites: data?.generalites,
          }}
          colon={isEditable}
          onFinish={handleSubmit}
        >
          <div className="data-table data-table__generalites mb-6">
            <Typography.Title level={2}>Generalites</Typography.Title>
            <div className="data-table__body">{renderDataGeneralites()}</div>
          </div>

          <div className="data-table">
            <Typography.Title level={2}>ENVELOPPE</Typography.Title>
            {renderDataEnveloppe()}
          </div>
          {/* <div className="mt-4 text-right">
            <Button
              className="md:w-3/12 w-6/12"
              htmlType="submit"
              type="primary"
              size="large"
            >
              Submit
            </Button>
          </div> */}
        </Form>
      </div>

      <Modal
        open={isShowModal}
        title={modalData?.content?.title}
        onCancel={() => setIsShowModal(false)}
        footer={null}
      >
        <img src={modalData?.content.img} alt="thumbnail" />
        <p className="mt-2">{modalData?.content?.message}</p>
      </Modal>
      {contextHolder}
    </main>
  );
}

export default EditPage;
