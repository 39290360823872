export const paths = {
  mockData() {
    return `https://pst2ptf774f5e6tabql7g4tle40dyetm.lambda-url.eu-west-1.on.aws`;
  },
  modalData() {
    return `https://tnorlolbmzkwo6gxf5u5awukke0senxc.lambda-url.eu-west-1.on.aws`;
  },
  autoCompleteSearch(search: string) {
    return `https://api-adresse.data.gouv.fr/search/?q=${search}`;
  },
  listingTable() {
    return "https://akwaiji6qo3z5qy2tyeq4amvh40uhqfc.lambda-url.eu-west-1.on.aws";
  },
  getDataUpload(fileName: string) {
    return `https://zlmqrmqcbckmsovecorupci2qu0uvcxr.lambda-url.eu-west-1.on.aws/?file_name=${fileName}`;
  },
  getHomeUploadData() {
    // return `https://clzsw527uy4kzfl6btmf2wqnd40ipazn.lambda-url.eu-west-1.on.aws`;
    return `https://clzsw527uy4kzfl6btmf2wqnd40ipazn.lambda-url.eu-west-1.on.aws/`;
  },
  getResultat() {
    // return "https://cfq45qf2zqdh63lbs55pieskia0merqq.lambda-url.eu-west-1.on.aws/";
    return "https://cfq45qf2zqdh63lbs55pieskia0merqq.lambda-url.eu-west-1.on.aws/";
  },
  getGEOMap() {
    return "https://gist.githubusercontent.com/wavded/1200773/raw/e122cf709898c09758aecfef349964a8d73a83f3/sample.json";
  },
  submitResultat() {
    return "https://keddeb4tq2jpb5m4n6hlq3x7hq0isjcu.lambda-url.eu-west-1.on.aws";
  },
};
