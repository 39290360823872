export const TYPE_FIELD = {
  SELECT: "select",
  INPUT_NUMBER: "number_input",
  INPUT_TEXT: "text_input",
  CHECKBOX: "checkbox",
};

export const TypeEnveloppe = {
  MUR: "mur",
  WINDOW: "window",
};

export const SetOfFields = {
  [TypeEnveloppe.MUR]: {
    exposition: TYPE_FIELD.SELECT,
    surface: TYPE_FIELD.INPUT_NUMBER,
    type_local_adjacent: TYPE_FIELD.INPUT_TEXT,
    materiau: TYPE_FIELD.INPUT_TEXT,
    epaisseur: TYPE_FIELD.INPUT_NUMBER,
    isolation: TYPE_FIELD.CHECKBOX,
  },
  [TypeEnveloppe.WINDOW]: {
    size: TYPE_FIELD.INPUT_NUMBER,
    isolation: TYPE_FIELD.CHECKBOX,
  },
};

export const enveloppeOptions = [
  { label: "Wall", value: TypeEnveloppe.MUR },
  { label: "Window", value: TypeEnveloppe.WINDOW },
];

export const expositionOptions = [
  { label: "Nord Est", value: "NE" },
  { label: "Sud Est", value: "SE" },
];

export const MappingRenderField = {
  exposition: TYPE_FIELD.INPUT_TEXT,
  surface: TYPE_FIELD.INPUT_NUMBER,
  type_local_adjacent: TYPE_FIELD.INPUT_TEXT,
  materiau: TYPE_FIELD.INPUT_TEXT,
  epaisseur: TYPE_FIELD.INPUT_NUMBER,
  isolation: TYPE_FIELD.CHECKBOX,
  size: TYPE_FIELD.INPUT_NUMBER,
};

export const MappingRenderFieldGeneralites = {
  dept: TYPE_FIELD.INPUT_TEXT,
  alt: TYPE_FIELD.INPUT_NUMBER,
  year_cons: TYPE_FIELD.INPUT_TEXT,
  livable_surface_housing: TYPE_FIELD.INPUT_NUMBER,
  livable_surface_building: TYPE_FIELD.INPUT_NUMBER,
  nb_floors: TYPE_FIELD.INPUT_NUMBER,
  avg_height_floor: TYPE_FIELD.INPUT_NUMBER,
};

export const typeBienOptions = [
  { label: "Appart", value: "appart" },
  { label: "House", value: "house" },
];

export const MATERIAL_VALUE = {
  MURS_EN_PIERRE_DE_TAILLE_ET_MOELLONS: "Murs en pierre de taille et moellons",
  MURS_PISE_BETON_TERRE_STABILISE: "murs_pise_beton_terre_stabilise",
  MURS_EN_PANS_DE_BOIS: "Murs en pans de bois",
  MURS_BOIS_RONDINS: "murs_bois_rondins",
  MURS_EN_BRIQUES_PLEINES: "Murs en briques pleines",
  MURS_EN_BRIQUES_CREUSES: "murs_briques_creuses",
  MURS_EN_BLOCS_DE_BETON_PLEINS: "murs_blocs_beton_pleins",
  MURS_EN_BLOCS_DE_BETON_CREUX: "murs_blocs_beton_creux",
  MURS_EN_BETON: "Murs en béton",
  BRIQUE_TERRE_CUITE_ALVEOLAIRE: "brique_terre_cuite_alveolaire",
  MUR_EN_BETON_CELLULAIRE: "murs_beton_cellulaire",
  MURS_SANDWICH_BETON_ISOLANT_BETON_SANS_ISOLATION_RA: "murs_sandwich",
  MURS_EN_OSSATURE_BOIS: "Murs en ossature bois",
};

export const MATERIAL_OPTIONS = [
  {
    label: "Murs en pierre de taille et moellons",
    value: MATERIAL_VALUE.MURS_EN_PIERRE_DE_TAILLE_ET_MOELLONS,
  },
  {
    label: "Murs en pisé ou béton de terre stabilisé",
    value: MATERIAL_VALUE.MURS_PISE_BETON_TERRE_STABILISE,
  },
  {
    label: "Murs en pans de bois",
    value: MATERIAL_VALUE.MURS_EN_PANS_DE_BOIS,
  },
  {
    label: "Murs bois (rondins)",
    value: MATERIAL_VALUE.MURS_BOIS_RONDINS,
  },
  {
    label: "Murs en briques pleines",
    value: MATERIAL_VALUE.MURS_EN_BRIQUES_PLEINES,
  },
  {
    label: "Murs en briques creuses",
    value: MATERIAL_VALUE.MURS_EN_BRIQUES_CREUSES,
  },
  {
    label: "Murs en blocs de béton pleins",
    value: MATERIAL_VALUE.MURS_EN_BLOCS_DE_BETON_PLEINS,
  },
  {
    label: "Murs en blocs de béton creux",
    value: MATERIAL_VALUE.MURS_EN_BLOCS_DE_BETON_CREUX,
  },
  {
    label: "Murs en béton",
    value: MATERIAL_VALUE.MURS_EN_BETON,
  },
  {
    label: "Brique terre cuite alvéolaire",
    value: MATERIAL_VALUE.BRIQUE_TERRE_CUITE_ALVEOLAIRE,
  },
  {
    label: "Mur en béton cellulaire",
    value: MATERIAL_VALUE.MUR_EN_BETON_CELLULAIRE,
  },
  {
    label: "Murs sandwich béton/isolant/béton (sans isolation rapportée)",
    value: MATERIAL_VALUE.MURS_SANDWICH_BETON_ISOLANT_BETON_SANS_ISOLATION_RA,
  },
  {
    label: "Murs en ossature bois",
    value: MATERIAL_VALUE.MURS_EN_OSSATURE_BOIS,
  },
];

export const MATERIAL_RADIO_OPTIONS: any = {
  [MATERIAL_VALUE.MURS_EN_PIERRE_DE_TAILLE_ET_MOELLONS]: [
    {
      label: "Inconnu",
      value: "murs_pierre_taille_moellons_inconnu",
    },
    {
      label: "Murs constitués d’un seul matériau",
      value: "murs_pierre_taille_moellons_constitue_seul_materiau",
    },
    {
      label: "Murs avec remplissage tout venant",
      value: "murs_pierre_taille_moellons_remplissage_tout_venant",
    },
  ],
  [MATERIAL_VALUE.MURS_EN_PANS_DE_BOIS]: [
    {
      label: "Sans remplissage tout venant",
      value: "murs_pans_bois_sans_remplissage_tout_venant",
    },
    {
      label: "Avec remplissage tout venant",
      value: "murs_pans_avec_sans_remplissage_tout_venant",
    },
  ],
  [MATERIAL_VALUE.MURS_EN_BRIQUES_PLEINES]: [
    {
      label: "simples",
      value: "murs_briques_pleines_simples",
    },
    {
      label: "doubles avec lame d’air",
      value: "murs_briques_pleines_doubles",
    },
  ],
  [MATERIAL_VALUE.MURS_EN_BETON]: [
    {
      label: "banché",
      value: "murs_beton_banche",
    },
    {
      label: "de mâchefer",
      value: "murs_beton_machefer",
    },
  ],
  [MATERIAL_VALUE.MURS_EN_OSSATURE_BOIS]: [
    {
      label: "avec isolant en remplissage",
      value: "murs_ossature_bois_isolant_remplissage",
    },
    {
      label: "avec remplissage tout venant",
      value: "murs_ossature_bois_remplissage_tout_venant",
    },
    {
      label: "sans remplissage",
      value: "murs_ossature_bois_sans_remplissage",
    },
  ],
};

export const THICKNESS_OPTIONS: any = {
  murs_pierre_taille_moellons_constitue_seul_materiau:
    "≤20 25 30 35 40 45 50 55 60 65 70 75 ≥80".split(" ").map((item) => ({
      label: item,
      value: item,
    })),
  murs_pise_beton_terre_stabilise: "≤40 45 50 55 60 65 70 75 ≥80"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_pans_bois_sans_remplissage_tout_venant: "≤8 10 13 18 24 ≥32"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_bois_rondins: "≤10 15 20 ≥25".split(" ").map((item) => ({
    label: item,
    value: item,
  })),
  murs_briques_pleines_simples: "≤9 12 15 19 23 28 34 45 55 60 ≥70"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_briques_pleines_doubles: "≤20 25 30 35 45 50 ≥60"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_briques_creuses: "≤15 18 20 23 25 28 33 38 ≥43"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_blocs_beton_pleins: "≤20 23 25 28 30 33 35 38 ≥40"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_blocs_beton_creux: "≤20 23 ≥25".split(" ").map((item) => ({
    label: item,
    value: item,
  })),
  murs_beton_banche: "≤20 22,5 25 28 30 35 40 ≥45".split(" ").map((item) => ({
    label: item,
    value: item,
  })),
  brique_terre_cuite_alveolaire: "30 37,5".split(" ").map((item) => ({
    label: item,
    value: item,
  })),
  murs_beton_cellulaire: "15 17,5 20 22,5 25 27,5 30 32,5 35 37,5"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_sandwich: "≤15 20 ≥ 25".split(" ").map((item) => ({
    label: item,
    value: item,
  })),
  murs_ossature_bois_isolant_remplissage: "10 15 20 25 30 35 40 ≥45"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
  murs_ossature_bois_remplissage_tout_venant: "≤8 10 13 18 24 ≥32"
    .split(" ")
    .map((item) => ({
      label: item,
      value: item,
    })),
};
