import baseApi from "../base.api";
import { paths } from "../paths";

export const getMockData = () => {
  return baseApi.get(paths.mockData());
};

export const autoCompleteSearch = (search: string) => {
  return baseApi.get(paths.autoCompleteSearch(search));
};

export const getModalData = () => {
  return baseApi.get(paths.modalData());
};

export const getListingData = () => {
  return baseApi.get(paths.listingTable());
};

export const getDataUpload = (fileName: string) => {
  return baseApi.get(paths.getDataUpload(fileName));
};

export const uploadFile = (path: string, data: any) => {
  return baseApi.postFormData(path, data);
};

export const getHomeUploadData = (data: any) => {
  return baseApi.post(paths.getHomeUploadData(), data);
};

export const getResultat = (data: any) => {
  return baseApi.post(paths.getResultat(), data);
};

export const getGEOMap = () => {
  return baseApi.get(paths.getGEOMap());
};

export const submitResultat = (ref_ademe: string) => {
  return baseApi.post(paths.submitResultat(), { ref_ademe });
};
