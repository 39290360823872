import createStorage from "./createStorage";

const instance = createStorage("energy");
const KEY_PROJECT = "last_project";

export const setProject = (data: string) => {
  instance.setValue(KEY_PROJECT, data);
};

export const getProject = () => instance.getValue(KEY_PROJECT);

export const clearProject = () => {
  instance.deleteKey(KEY_PROJECT);
};
