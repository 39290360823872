import PAGES from "routes/constants";
import Personas1 from "assets/images/personas-1.jpg";
import Personas2 from "assets/images/sample-1.jpg";
import Personas3 from "assets/images/sample-2.jpg";

export const VOTRE_OPTIONS = [
  {
    label: "Vous êtes acheteur et voulez cela",
    labelHtml: (
      <p>
        A. Vous êtes <b>acheteur</b> et voulez cela
      </p>
    ),
    value: "A",
    url: PAGES.acheter,
    desc: "Maîtrisez la vraie valeur de votre bien, sans surprise pour la performance énergétique",
    thumbnail: Personas1,
  },
  {
    label: "Vous êtes proriétaire et voulez ceci",
    labelHtml: (
      <p>
        B. Vous êtes <b>proriétaire</b> et voulez ceci
      </p>
    ),
    value: "B",
    url: PAGES.vendre,
    desc: "Maximisez le prix de vente de votre bien en sachant proposer un DPE lisible et factuel ",
    thumbnail: Personas2,
  },
  {
    label: "Faites des travaux",
    labelHtml: (
      <p>
        C. faites des <b>travaux</b>
      </p>
    ),
    value: "C",
    url: PAGES.renover,
    desc: "Décidez des travaux à réaliser en tenant compte de leur impact sur la performance énergétique et leur coût ",
    thumbnail: Personas3,
  },
];
